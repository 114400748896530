<template >
  <div class="containter">
    <div class="commentArea">
      <van-field
        v-model="commentText"
        rows="7"
        maxlength="200"
        show-word-limit
        autosize
        type="textarea"
        placeholder="请输入您想评论的内容"
      />
    </div>
    <div class="countLine">
      <div class="leftField">
        <van-field
          v-model="digit"
          type="digit"
          label="任务得分"
          label-class="lableFont"
          :placeholder="'最高得分'+totalScore+'分'"
        />
      </div>
      <div class="rightField">分</div>
    </div>
    <div class="submitButton" @click="submit">完成</div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { taskSummeryApprove } from '../../api/task';
import * as dd from 'dingtalk-jsapi';

export default {
  name: 'ActivityComment',

  data() {
    return {
      id: '',
      commentText: '',
      digit: 0,
      totalScore: 0
    };
  },
  watch: {},
  created() {
    dd.biz.navigation.setTitle({
      title: '审批', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
    this.getRecords();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const { id, totalScore } = this.$route.query;
      this.id = id;
      this.totalScore = totalScore;
    },
    // 上传前校验
    beforeRead(file) {
      if (
        file.type !== 'image/jpeg' ||
        file.type !== 'image/png' ||
        file.type !== 'image/gif'
      ) {
        Toast('请上传 jpg/png/gif格式图片');
        return false;
      }
      return true;
    },
    // 上传服务器
    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';

      setTimeout(() => {
        file.status = 'failed';
        file.message = '上传失败';
      }, 1000);
    },
    submit() {
      const commentText = this.commentText;
      const digit = this.digit;
      const totalScore = this.totalScore;
      const { id } = this.$route.query;
      if (!commentText) {
        Notify({ type: 'warning', message: '审批评语不能为空' });
        return;
      }
      if (!digit) {
        Notify({
          type: 'warning',
          message: `请输入任务得分`
        });
        return;
      }
      if (digit > totalScore) {
        Notify({
          type: 'warning',
          message: `不能超过任务的总分(${totalScore}分)`
        });
        return;
      }

      taskSummeryApprove({
        auditId: id,
        opinion: commentText,
        score: digit
      })
        .then(res => {
          if (res.success) {
            Notify({ type: 'success', message: '总结成功' });
            this.$router.go(-1);
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.commentArea {
  width: 100%;
  height: 200 * $px;
  background-color: #ffffff;
  position: relative;
}
.imgLine {
  width: 100%;
  height: 90 * $px;
  position: absolute;
  bottom: 10 * $px;
  left: 0px;
}
.marginLeft12 {
  margin-left: 12 * $px;
}
.addImg {
  width: 80 * $px;
  height: 80 * $px;
}
.submitButton {
  margin: 30 * $px 15 * $px;
  width: 345 * $px;
  height: 44 * $px;
  border-radius: 22 * $px;
  text-align: center;
  line-height: 44 * $px;
  background-color: #ff192f;
  color: #ffffff;
  font-size: 16 * $px;
}
.countLine {
  width: 100%;
  height: 50 * $px;
  background-color: #ffffff;
  margin-top: 17 * $px;
}
.lableFont {
  font-size: 14 * $px;
  color: #555555 !important;
}
.leftField {
  width: 340 * $px;
  height: 100%;
  float: left;
}
.rightField {
  width: 35 * $px;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 14 * $px;
}
</style>



